function getInterfaceAddress(ipWithSubnet) {
    const ip = ipWithSubnet.split("/")[0];
    const mask = ipWithSubnet.split("/")[1];
    let ciscoMask;
    switch (mask) {
      case "16":
        ciscoMask = "255.255.0.0";
        break;
      case "8":
        ciscoMask = "255.0.0.0";
        break;
      default:
        ciscoMask = "255.255.255.0"; // mask 24
    }
    const result = ip + " " + ciscoMask;
    return result;
}

function getNetworks(network) {
    const ip = network.split("/")[0];
    const mask = network.split("/")[1];
    let ciscoReverseMask;
    switch (mask) {
      case "16":
        ciscoReverseMask = "0.0.255.255";
        break;
      case "8":
        ciscoReverseMask = "0.255.255.255";
        break;
      default:
        ciscoReverseMask = "0.0.0.255"; // mask 24
    }
    const result = ip + " " + ciscoReverseMask;
    return result;
}

const inputExternalCiscoIp = document.getElementById("external-cisco-ip"); // IP-адрес с маской
const inputExternalUTMIp = document.getElementById("external-ngfw-ip"); // IP-адрес без маски
const inputLocalCiscoIp = document.getElementById("local-cisco-ip"); // IP-адрес с маской
const inputLocalNetworkUTM = document.getElementById("local-utm-subnet"); // IP-адрес с маской
const inputLocalNetworkCisco = document.getElementById("local-cisco-subnet"); // IP-адрес с маской
const inputPSK = document.getElementById("psk-key"); // PSK ключ validation: string, min 10 letters max 256 letters
const inputKeyId = document.getElementById("psk-id"); // key-id validation: min 1 letter max 256 letter


const fields = [
    inputExternalCiscoIp,
    inputLocalCiscoIp,
    inputLocalNetworkCisco,
    inputLocalNetworkUTM,
    inputExternalUTMIp,
    inputPSK,
    inputKeyId,
];
let areGeneralFieldsValid = false;
let arePskFieldsValid = false;

for (let field of fields) {
    field.addEventListener('keyup', compileTexts);
}

const generateConfigButton = document.querySelector('.generate-config');
generateConfigButton.addEventListener('click', showConfig);

function download(args) {
    const pre_class = args.target.classList[0].replace('-download', '');
    const text = document.querySelector(`.${pre_class}`).textContent;

    const blob = new Blob([text], { type: 'text/plain' });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);

    link.href = url;
    link.download = `${pre_class}.rsc`;

    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
}

function copyToClipboard(args) {
    const pre_class = args.target.classList[0].replace('-copy', '');
    const text = document.querySelector(`.${pre_class}`).textContent;

    const hiddenInput = document.createElement('textarea');
    hiddenInput.type = 'text';
    hiddenInput.value = text;
    document.body.appendChild(hiddenInput);
    hiddenInput.select();
    document.execCommand('copy');
    hiddenInput.remove();
}

// Добавление обработчика событий кликов по кнопкам скачивания скриптов
for (let elem of document.querySelectorAll('button.download')) {
    elem.addEventListener('click', download);
}

// Добавление обработчика событий кликов по кнопкам копирования скриптов в буфер обмена
for (let elem of document.querySelectorAll('button.copy')) {
    elem.addEventListener('click', copyToClipboard);
}

const ciscoOutConfig = document.querySelector('.cisco-out-config');
const ciscoInConfig = document.querySelector('.cisco-in-config');

function switchClass(element, className) {
    let elements = document.querySelectorAll(`.${className}`);

    for (let elem of elements) {
        elem.classList.remove(className);
    }

    if (element) { 
        element.classList.add(className);
    }
}

function getConfigInString(data) {
    return `\
crypto ikev2 proposal ikev2proposal
    encryption aes-cbc-256
    integrity sha256
    group 19

crypto ikev2 policy ikev2policy
    match fvrf any
    proposal ikev2proposal

crypto ikev2 keyring key
    peer strongswan
    address ${data.externalUtmIp}
    identity key-id ${data.keyId}
    pre-shared-key local ${data.PSK}
    pre-shared-key remote ${data.PSK}

crypto ikev2 profile ikev2profile
    match identity remote address ${data.externalUtmIp} 255.255.255.255
    authentication remote pre-share
    authentication local pre-share
    keyring local key

crypto ipsec transform-set TS esp-gcm 256
    mode tunnel

crypto map cmap 10 ipsec-isakmp
    set peer ${data.externalUtmIp}
    set transform-set TS
    set ikev2-profile ikev2profile
    match address cryptoacl

interface GigabitEthernet1
    ip address ${getInterfaceAddress(data.externalCiscoIP)}
    ip nat outside
    negotiation auto
    no mop enabled
    no mop sysid
    crypto map cmap

interface GigabitEthernet2
    ip address ${getInterfaceAddress(data.localCiscoIP)}
    ip nat inside
    negotiation auto
    no mop enabled
    no mop sysid

ip nat inside source list NAT interface GigabitEthernet1 overload

ip access-list extended NAT
    deny   ip ${getNetworks(data.ciscoSubnet)} ${getNetworks(data.utmSubnet)}
    permit ip ${getNetworks(data.ciscoSubnet)} any
ip access-list extended cryptoacl
    permit ip ${getNetworks(data.ciscoSubnet)} ${getNetworks(data.utmSubnet)}
`;
}

function getConfigOutString(data) {
    return `\
crypto ikev2 proposal ikev2proposal
    encryption aes-cbc-256
    integrity sha256
    group 19

crypto ikev2 policy ikev2policy
    match fvrf any
    proposal ikev2proposal

crypto ikev2 keyring key
    peer strongswan
    address ${data.externalUtmIp}
    pre-shared-key local ${data.PSK}
    pre-shared-key remote ${data.PSK}

crypto ikev2 profile ikev2profile
    match identity remote key-id ${data.keyId}
    authentication remote pre-share
    authentication local pre-share
    keyring local key

crypto ipsec transform-set TS esp-gcm 256
    mode tunnel

crypto map cmap 10 ipsec-isakmp
    set peer ${data.externalUtmIp}
    set transform-set TS
    set ikev2-profile ikev2profile
    match address cryptoacl

interface GigabitEthernet1
! внешний интерфейс
    ip address ${getInterfaceAddress(data.externalCiscoIP)}
    ip nat outside
    negotiation auto
    no mop enabled
    no mop sysid
    crypto map cmap

interface GigabitEthernet2
! локальный интерфейс
    ip address ${getInterfaceAddress(data.localCiscoIP)}
    ip nat inside
    negotiation auto
    no mop enabled
    no mop sysid

ip nat inside source list NAT interface GigabitEthernet1 overload

ip access-list extended NAT
    deny   ip ${getNetworks(data.ciscoSubnet)} ${getNetworks(data.utmSubnet)}
    permit ip ${getNetworks(data.ciscoSubnet)} any
ip access-list extended cryptoacl
    permit ip ${getNetworks(data.ciscoSubnet)} ${getNetworks(data.utmSubnet)}
`;
}

function pinError(field, regex = null) {
    if (!regex) {
        if (field.value) {
            field.parentElement.classList.remove('error');
        } else {
            field.parentElement.classList.add('error');
        }
    } else {
        if (!field.value || !regex.exec(field.value)) {
            field.parentElement.classList.add('error');
        } else {
            field.parentElement.classList.remove('error');
        }
    }
}

function checkFields() {

    const ipValidationRegexp = /^(\d{1,3}\.){3}\d{1,3}$/;
    const subnetValidationRegexp = /^(\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/; // TODO: валидация должна работать только с 16, 32, 24 маской.

    pinError(inputExternalCiscoIp, subnetValidationRegexp);
    pinError(inputExternalUTMIp, ipValidationRegexp);
    pinError(inputLocalCiscoIp, subnetValidationRegexp);
    pinError(inputLocalNetworkUTM, subnetValidationRegexp);
    pinError(inputLocalNetworkCisco, subnetValidationRegexp);
    pinError(inputPSK, /^[ -~]{10,256}$/);
    pinError(inputKeyId);

    let generalFieldsValid = true;
    let pskFieldsValid = true;

    for (let field of fields) {
        if (field.parentElement.classList.contains('error')) {
            if (field.classList.contains('psk-key') || field.classList.contains('psk-id')) {
                pskFieldsValid = false;
            } else {
                generalFieldsValid = false;
            }
        }
    }
    arePskFieldsValid = pskFieldsValid;
    areGeneralFieldsValid = generalFieldsValid;
    return generalFieldsValid && pskFieldsValid;
}

function compileTexts() {
    checkFields();
    const externalUtmIp = inputExternalUTMIp.value;
    const keyId = inputKeyId.value;
    const PSK = inputPSK.value;
    const externalCiscoIP = inputExternalCiscoIp.value;
    const localCiscoIP = inputLocalCiscoIp.value;
    const ciscoSubnet = inputLocalNetworkCisco.value;
    const utmSubnet = inputLocalNetworkUTM.value;

    const data = {
        externalUtmIp,
        keyId,
        PSK,
        externalCiscoIP,
        localCiscoIP,
        ciscoSubnet,
        utmSubnet,
    };

    ciscoOutConfig.textContent = getConfigOutString(data);
    ciscoInConfig.textContent = getConfigInString(data);
}

function showConfig(args) {
    if (!arePskFieldsValid || !areGeneralFieldsValid) return;
    switchClass(args.target, 'active');
    let targetRenderedElement = document.querySelector('div.rendered-data');
    switchClass(targetRenderedElement, 'active-render');
};

compileTexts();

// TODO: если валидация не проходит то конфиг закрывать, кнопка не должна работать